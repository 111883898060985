import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "delete-reviews"
    }}>{`Delete Reviews`}</h1>
    <hr></hr>
    <ol>
      <li parentName="ol">{`While in the Reviews section of the Reading Tracker, patrons can delete reviews they have left on items. To toggle to the reviews section, select 'Reviews' at the top of the screen. `}</li>
      <li parentName="ol">{`A list of reviews that the patron has left will be displayed in the 'Your Reviews' page. `}</li>
      <li parentName="ol">{`Select the review that you wish to delete. This action will load that item's review display. `}</li>
    </ol>
    <p><img alt="Reading Tracker Review List Screen" src={require("./images/reading_tracker_review_list.png")} />{` `}<img alt="Reading Tracker Submit Review Screen" src={require("./images/reading_tracker_submit_review.png")} /></p>
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`Select 'Remove Review' and confirm that you want to delete the review.`}</li>
    </ol>
    <p><img alt="Reading Tracker Confirm Deletion" src={require("./images/reading_tracker_confirm_delete.png")} />{` `}<img alt="Reading Tracker Deleted Review" src={require("./images/reading_tracker_deleted_review.png")} /></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      